<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <details
        class="disclosure"
        ref="disclosure"
    >
        <summary class="title">
            <Image
                filePath="filter.svg"
                alternativeText="Filter Icon"
            />
            Filters
        </summary>
        <Form class="filters">
            <div class="scroll-content">
                <Select
                    label="Order By"
                    v-model="filter.orderBy"
                    :options="orderOptions"
                />
                <Field
                    label="Job Title"
                    v-model="filter.title"
                    placeholder="Enter a job role..."
                />
                <Field
                    label="Location"
                    v-model="filter.location"
                    placeholder="Enter a job location..."
                />
                <Field
                    label="Distance"
                    v-model="filter.distance"
                    type="range"
                    suffix="miles"
                    :min="3"
                    :max="100"
                />
                <Field
                    label="Hours"
                    v-model="filter.upperHours"
                    type="range"
                    prefix=""
                    suffix="per week"
                    :min="0"
                    :max="72"
                />
                <Field
                    label="Minimum Wage"
                    v-model="filter.wageRate"
                    type="range"
                    prefix="£"
                    suffix="per hour"
                    :min="0"
                    :max="60"
                />
                <Field
                    label="Minimum Salary"
                    v-model="filter.salaryRate"
                    type="range"
                    prefix="£"
                    suffix="per year"
                    :min="0"
                    :max="100000"
                />
                <Checkbox
                    label="Paid Breaks"
                    v-model="filter.paidBreaks"
                />
                <Checkbox
                    label="Joining Bonus"
                    v-model="filter.joiningBonus"
                />
                <!--<Checkbox
                    label="Requires Car"
                    v-model="filter.hasCar" carOptions
                />-->
                <Select
                    label="Requires Car"
                    v-model="filter.hasCar"
                    :options="carOptions"
                />
                <div
                    class="checkbox-container"
                >
                    <header>Type</header>
                    <div>
                        <Checkbox
                            v-for="(item, index) in contractTypeOptions"
                            :key="index"
                            :label="item.label"
                            :inputValue="item.value"
                            v-model="filter.type"
                            class="multi-checkbox"
                        />
                    </div>
                </div>
                <div
                    class="checkbox-container"
                >
                    <header>Industry</header>
                    <div>
                        <Checkbox
                            v-for="(item, index) in industryOptions"
                            :key="index"
                            :label="item.label"
                            :inputValue="item.value"
                            v-model="filter.industry"
                            class="multi-checkbox"
                        />
                    </div>
                </div>
                <div
                    class="checkbox-container"
                >
                    <header>Availability</header>
                    <div>
                        <Checkbox
                            v-for="(item, index) in availabilityOptions"
                            :key="index"
                            :label="item.label"
                            :inputValue="item.value"
                            v-model="filter.availability"
                            class="multi-checkbox"
                        />
                    </div>
                </div>
                <Field
                    label="Keyword(s)"
                    v-model="filter.keywords"
                    placeholder="Enter some keywords..."
                />
            </div>
            <section class="configuration">
                <Button
                    label="Clear Filters"
                    :action="clearFilter"
                    title="Clear Filters"
                />
            </section>
            <Button
                label="Apply Filters"
                :action="applyFilter"
                title="Apply Filters"
                class="button-contrast"
            />
        </Form>
    </details>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Form from '@/components/generic/Form';
        import Select from '@/components/generic/Select';
        import Field from '@/components/generic/Field';
        import Checkbox from '@/components/generic/Checkbox';
        import Image from '@/components/generic/Image';
        import Button from '@/components/generic/Button';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Form,
            Select,
            Field,
            Checkbox,
            Image,
            Button
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                location: '',
                filterDefaults: {
                    orderBy: 'Best Match',
                    location: '',
                    distance: '50',
                    upperHours: '72',
                    wageRate: '0',
                    salaryRate: '0',
                    joiningBonus: false,
                    paidBreaks: false,
                    hasCar: -1,
                    type: ['Permanent', 'Temporary', 'Maternity Cover', 'Part-Time', 'Full-Time', 'Contract', 'Zero Hours'],
                    industry: ['Older People', 'Supported Living', 'DomCare'],
                    availability: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                    title: '',
                    keywords: '',
                },
                filter: this.$store.getters.Filter,
                carOptions: [
                    {
                        label: 'Not filtered',
                        value: -1
                    },
                    {
                        label: 'Car not required',
                        value: 0
                    },
                    {
                        label: 'Car required',
                        value: 1
                    }
                ],
                orderOptions: [
                    {
                        label: 'Best Match',
                        value: 'Best Match'
                    },
                    {
                        label: 'Highest Pay',
                        value: 'Highest Pay'
                    },
                    {
                        label: 'Date Posted',
                        value: 'Date Posted'
                    },
                    {
                        label: 'Distance',
                        value: 'Distance'
                    }
                ],
                typeOptions: [
                    {
                        label: 'Best Match',
                        value: 'Best Match'
                    },
                    {
                        label: 'Highest Pay',
                        value: 'Highest Pay'
                    },
                    {
                        label: 'Date Posted',
                        value: 'Date Posted'
                    },
                    {
                        label: 'Distance',
                        value: 'Distance'
                    }
                ],
                contractTypeOptions: [
                    {
                        label: 'Permanent',
                        value: 'Permanent'
                    },
                    {
                        label: 'Temporary',
                        value: 'Temporary'
                    },
                    {
                        label: 'Maternity Cover',
                        value: 'Maternity Cover'
                    },
                    {
                        label: 'Part Time',
                        value: 'Part-Time'
                    },
                    {
                        label: 'Full Time',
                        value: 'Full-Time'
                    },
                    {
                        label: 'Contract',
                        value: 'Contract'
                    },
                    {
                        label: 'Zero Hours',
                        value: 'Zero Hours'
                    }
                ],
                industryOptions: [
                    {
                        label: 'Supported Living',
                        value: 'Supported Living'
                    },
                    {
                        label: 'Older People',
                        value: 'Older People'
                    },
                    {
                        label: 'DomCare',
                        value: 'DomCare'
                    }
                ],
                availabilityOptions: [
                    {
                        label: 'Monday',
                        value: 'Monday'
                    },
                    {
                        label: 'Tuesday',
                        value: 'Tuesday'
                    },
                    {
                        label: 'Wednesday',
                        value: 'Wednesday'
                    },
                    {
                        label: 'Thursday',
                        value: 'Thursday'
                    },
                    {
                        label: 'Friday',
                        value: 'Friday'
                    },
                    {
                        label: 'Saturday',
                        value: 'Saturday'
                    },
                    {
                        label: 'Sunday',
                        value: 'Sunday'
                    }
                ]
            };
        };

    //

    // METHODS

        // Apply filter.
        const applyFilter = function () {

            // Emit update event, passing filter object.
            this.$emit('update', this.filter);

            // update the store.
            this.$store.dispatch(
                'updateFilterTable',
                this.filter
            );

            // Close disclosure element.
            this.$refs.disclosure.open = false;

        };

        // Clear filter.
        const clearFilter = function () {
            // Initialise location state.
            this.location = this.$route.query.location;

            // Restore default filter values.
            this.filter = { ...this.filterDefaults };

            // refresh the location into the object before updating the store.
            this.filter.location = this.location;

            // Update the object in the store.
            this.$store.dispatch(
                'updateFilterData',
                this.filter
            );

            // Apply filter.
            this.applyFilter();

        };

        // Define methods.
        const methods = {
            applyFilter,
            clearFilter
        };

    //

    // BEFORE MOUNT

        // Define before mount.
        const beforeMount = function () {

            // If location query parameter has not been defined.
            if (!this.$route.query.location) {

                // Define location query parameter.
                this.$route.query.location = 'Plymouth';   

            }

            // Initialise location state.
            this.location = this.$route.query.location;

            // Fetch the filter that is currently stored in store.
            this.filter = this.$store.getters.Filter

            // set the location of the filter.
            this.filter.location = this.location;

            // Update the object in the store.
            this.$store.dispatch(
                'updateFilterData',
                this.filter
            );
        };

    //

    // MOUNTED

        // Define mounted.
        const mounted = function () {

            // Invoke the 'applyFilter' method.
            this.applyFilter();

        }

    //

    // EXPORT

        export default {
            name: 'JobFilter',
            components,
            data,
            methods,
            beforeMount,
            mounted
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .disclosure {
            position: relative;
        }

        .title {
            padding: 1rem;
            background-color: $colour-secondary;
            color: $colour-light;
            font-size: 1.1rem;
            font-weight: 500;
            border-radius: .5rem;
            display: flex;
            align-items: center;
            gap: .5rem;
            min-height: 4rem;
        }

        .filters {
            display: grid;
            gap: 1rem;
            margin: 0 .5rem;
            position: absolute;
            width: calc(100% - 1rem);
            background-color: $colour-primary;
            padding: 1rem;
            border: $layout-border;
            border-top-width: 0;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            box-shadow: inset 0 .5rem .5rem -.5rem $colour-shadow, 0 .5rem .75rem -.5rem $colour-shadow;
            z-index: 9;
        }

        .scroll-content {
            display: grid;
            gap: 1rem;
            max-height: 40vh;
            overflow: {
                x: hidden;
                y: scroll
            }
            @media screen and (min-width: $large) {
                padding-right: 1rem;
            }
        }

        .advanced {
            display: grid;
            gap: 1rem;
        }

        .checkbox-container {
            header {
                display: grid;
                align-items: center;
                padding: 0;
                text-align: left;
                font-weight: 500;
                font-size: .9rem;
                min-height: 2rem;
                color: $colour-light;
                @media screen and (min-width: $large) {
                    font-size: .8rem;
                }
            }
            > div {
                display: grid;
                border-radius: .5rem;
                margin-top: .5rem;
                background-color: $colour-light;
                padding: .5rem .75rem;
                div {
                    padding: 0 .25rem;
                    &:nth-of-type(even) {
                        background-color: $colour-dark-trans;
                    }
                }
            }
        }

        .configuration {
            display: grid;
            justify-content: flex-end;
            gap: .5rem;
            margin: 0 .5rem;
        }

        .button-contrast {
            background-color: $colour-contrast;
        }

    //

</style>
