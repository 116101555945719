<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div>
        <ValidationPrompter
            :messages="validationMessages"
        />
        <label>
            <div>
                {{ label }}
            </div>
            <select
                :class="invalid ? 'invalid' : ''"
                :value="modelValue"
                @change="update"
            >
                <option disabled value="">{{ placeholder }}</option>
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option.value"
                >
                    {{ option.label }}
                </option>
            </select>
        </label>
    </div>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import ValidationPrompter from '@/components/generic/ValidationPrompter';

    //

    // COMPONENTS

        // Define components.
        const components = {
            ValidationPrompter
        };

    //

    // PROPS

        // Define props.
        const props = {
            label: {
                type: String
            },
            placeholder: {
                type: String,
                default: 'Select a value...'
            },
            options: {
                type: Array
            },
            validation: {
                type: Array
            },
            modelValue: {}
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                validationMessages: null
            };
        };

    //

    // COMPUTED

        // Determine whether input value is valid.
        const invalid = function () {
            return this.validationMessages && this.validationMessages.length ? true : false;
        };

        // Define computed.
        const computed = {
            invalid
        };

    //

    // METHODS

        // Update model value.
        const update = function (event) {

            // Define alias for input value.
            const value = event.target.value;

            // Validate input value.
            this.validate(value);

            // Emit update method, passing input value.
            this.$emit(
                'update:modelValue',
                value
            );

        };

        // Validate.
        const validate = function (value) {

            // If validation functions have been defined.
            if (this.validation) {

                // Define array to store validation messages.
                const messages = [];

                // For each validation function.
                for (const index in this.validation) {

                    // Define alias for function.
                    const validationFunction = this.validation[index];

                    // Invoke validation function, passing input value.
                    const response = validationFunction(value);

                    // If string is returned.
                    if (typeof response === 'string') {

                        // Push message to array.
                        messages.push(response);

                    }

                }

                // Update validation messages state.
               this.validationMessages = messages.length ? messages : null;

            }

        };

        // Define methods.
        const methods = {
            update,
            validate
        };

    //

    // EXPORT

        export default {
            name: 'Input',
            components,
            props,
            data,
            computed,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        div {
            display: grid;
            gap: 0;
        }

        label {
            @extend .input-label;
        }

        select {
            @extend .input-element;
            background-color: $colour-light;
        }

    //

    // CLASSES

        .dark {
            label {
                color: $colour-contrast;
                select {
                    background-color: $colour-dark-trans;
                }
            }
        }

    //

</style>
