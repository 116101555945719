<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <form @submit.prevent="">
        <slot ref="2" />
    </form>
</template>


<script>

    // DATA

        // Define data.
        const data = function () {
            return {

            }
        };

    //

    // EXPORT

        export default {
            name: 'Form',
            data
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

    //

</style>
