<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <output 
        v-if="messages"
        class="validation-prompt"
    >
        <ul>
            <li
                v-for="(message, index) in messages"
                :key="index"
            >
                {{ message }}
            </li>
        </ul>
    </output>
</template>


<script>

    // PROPS

        // Define props.
        const props = {
            messages: {
                type: Array
            }
        };

    //

    // EXPORT

        export default {
            name: 'ValidationPrompter',
            props
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        ul {
            position: absolute;
            bottom: .25rem;
            left: 0;
            padding: .75rem;
            margin: 0;
            background-color: $colour-warning-trans;
            font-size: .75rem;
            list-style: none;
            text-align: left;
            border-radius: .5rem;
            border: $layout-border;
            backdrop-filter: blur(5px);
            color: $colour-light;
        }

    //

    // CLASSES

        .validation-prompt {
            position: relative;
            padding: 0;
        }

    //

</style>
