<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div class="wrapper">
        <ValidationPrompter
            :messages="validationMessages"
        />
        <label>
            <div>
                {{ label }}
            </div>
            <div class="container">
                <output
                    v-if="type === 'range'"
                >
                    <template v-if="prefix">
                        {{ `${prefix} ` }}
                    </template>
                    {{ modelValue }}
                    <template v-if="suffix">
                        {{ ` ${suffix}` }}
                    </template>
                </output>
                <input
                    ref="input"
                    :type="type"
                    :placeholder="placeholder"
                    :class="isValid ? '' : 'invalid'"
                    :min="min"
                    :max="max"
                    :value="modelValue"
                    :checked="modelValue"
                    @input="update"
                />
            </div>
        </label>
    </div>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import ValidationPrompter from '@/components/generic/ValidationPrompter';

    //

    // COMPONENTS

        // Define components.
        const components = {
            ValidationPrompter
        };

    //

    // PROPS

        // Define props.
        const props = {
            type: {
                type: String,
                default: 'text'
            },
            label: {
                type: String
            },
            placeholder: {
                type: String,
                default: 'Enter a value...'
            },
            validation: {
                type: Array
            },
            modelValue: {},
            min: {
                type: Number
            },
            max: {
                type: Number
            },
            prefix: {
                type: String
            },
            suffix: {
                type: String
            }
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                validationMessages: null
            };
        };

    //

    // COMPUTED

        // Determine whether input value is valid.
        const isValid = function () {
            return this.validationMessages && this.validationMessages.length ? false : true;
        };
 
        // Define computed.
        const computed = {
            isValid
        };

    //

    // METHODS

        // Update model value.
        const update = function () {

            // Validate input value.
            this.validate();

            // Emit update method, passing input value.
            this.$emit(
                'update:modelValue',
                this.$refs.input.value
            );

        };

        // Validate.
        const validate = function () {

            // If validation functions have been defined.
            if (this.validation) {

                // Define array to store validation messages.
                const messages = [];

                // For each validation function.
                for (const index in this.validation) {

                    // Define alias for function.
                    const validationFunction = this.validation[index];

                    // Invoke validation function, passing input value.
                    const response = validationFunction(this.$refs.input.value);

                    // If string is returned.
                    if (typeof response === 'string') {

                        // Push message to array.
                        messages.push(response);

                    }

                }

                // Update validation messages state.
               this.validationMessages = messages.length ? messages : null;

            }

            // Return validation state.
            return this.isValid;

        };

        // Define methods.
        const methods = {
            update,
            validate
        };

    //

    // EXPORT

        export default {
            name: 'Field',
            components,
            props,
            data,
            computed,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        input {
            @extend .input-element;
        }

        label {
            @extend .input-label;
        }

        output {
            width: 100%;
            color: $colour-dark;
            background-color: $colour-light;
            padding: .5rem;
            border-radius: .5rem;
            text-align: center;
        }

    //

    // CLASSES

        .wrapper {
            display: grid;
            gap: 0;
        }

        .container {
            display: grid;
            gap: 0;
            justify-items: center;
        }

        .location-input {
            label {
                display: flex;
                gap: 0;
                div {
                    input {
                        height: 4rem;
                        border-radius: 4rem;
                        font-size: 1rem;
                        font-weight: 500;
                        padding: 1rem 2rem;
                        @media screen and (min-width: $small) {
                            width: 450px;
                        }
                    }
                }
            }
        }

        .dark {
            label {
                color: $colour-contrast;
                .container input {
                    background-color: $colour-dark-trans;
                }
            }
        }

    //

</style>
