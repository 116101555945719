<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <Main>
        <PageTitle label="Job Search" />
        <div class="content">
            <JobFilter
                @update="updateFilter"
                ref="filter"
                class="filter"
            />
            <Throbber v-if="isLoading" />
            <template v-else>
                <template
                    v-if="hasResults"
                >
                    <JobSummary
                        v-for="(job, index) in jobs"
                        :key="index"
                        :id="job.JobID"
                        :siteURL="job.SiteURL"
                        :title="job.JobTitle"
                        :provider="job.ProviderName"
                        :location="job.LocationSummary"
                        :distance="job.DistanceText"
                        :pay="job.PayDetails"
                        :description="job.SummaryText"
                        :promoted="job.Promoted"
                        :hours="job.NumberHours"
                        :needsCar="job.HasCar"
                    />
                </template>
                <div
                    v-else
                    class="no-results"
                >
                    <div class="no-results-title">No Jobs Found</div>
                    <p class="message">Refine your filter criteria or reset the filter below to continue your job search.</p>
                    <iframe class="formFrame" src="https://forms.zohopublic.eu/carecontrolsystemsltd/form/FindmeaCareJob/formperma/IH8JkcGQaYFnUImM4VZuQ0c8Zr9a4-AdkOjbPrOutck"></iframe>
                    <Button
                        label="Reset Filter"
                        title="Reset Filter"
                        class="reset"
                        :action="resetFilter"
                    />
                </div>
            </template>
        </div>
    </Main>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Main from '@/components/generic/Main';
        import PageTitle from '@/components/generic/PageTitle';
        import JobFilter from '@/components/modules/JobFilter';
        import Throbber from '@/components/generic/Throbber';
        import JobSummary from '@/components/modules/JobSummary';
        import Button from '@/components/generic/Button';
        import SadCloud from '@/components/generic/SadCloud';

        // Common.
        import { postRequest } from '@/common/API';
        import { generateKey, resolveWeekArray } from '@/common/Utilities';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Main,
            PageTitle,
            JobFilter,
            Throbber,
            JobSummary,
            Button,
            SadCloud
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                isLoading: true,
                filter: null,
                jobs: []
            };
        };

    //

    // COMPUTED

        // Has results.
        const hasResults = function () {
            return this.jobs.length > 0 ? true : false;
        };

        // Define computed.
        const computed = {
            hasResults
        };

    //

    // METHODS

        // Update filter.
        const updateFilter = function (value) {

            // Update filter state with passed value parameter.
            this.filter = { ...value };

            // Invoke the 'getJobListings' method.
            this.getJobListings();

        };

        // Get job listings.
        const getJobListings = async function () {

            // Set 'isLoading' state to true.
            this.isLoading = true;

            // Define API request object.
            const request = {
                target: this.$store.getters.API.host,
                endPoint: this.$store.getters.API.path + "/HTML/searchJobs.aspx",
                body: [
                    {
                        key: "JobSearch",
                        value: {
                            JobSearch: {
                                SecurityKey: generateKey(),
                                Location: this.filter.location,
                                Distance: this.filter.distance,
                                Industry: this.filter.industry.join(','),
                                JobTitle: this.filter.title,
                                UpperHours: this.filter.upperHours,
                                LowerHourlyRate: this.filter.wageRate,
                                LowerSalaryRate: this.filter.salaryRate,
                                DaysWork: resolveWeekArray(this.filter.availability),
                                ContractType: this.filter.type.join(','),
                                PaidBreaks: this.filter.paidBreaks ? '1' : '0',
                                HasCar: this.filter.hasCar,
                                JoiningBonus: this.filter.joiningBonus ? '1' : '0',
                                KeywordSearch: this.filter.keywords,
                                OrderPreference: this.filter.orderBy
                            }
                        }
                    }
                ]
            };

            // Make post request.
            const response = await postRequest(request);

            // If response successful.
            if (response && Object.prototype.hasOwnProperty.call(response, 'SummaryJobDetails')) {

                // Update 'jobs' state value.
                this.jobs = response.SummaryJobDetails;

            } else {

                // Clear 'jobs' state value.
                this.jobs = [];

            }

            // Set 'isLoading' state to false.
            this.isLoading = false;

        };

        // Reset filter.
        const resetFilter = function () {

            // Invoke the filter component's 'clearFilter' method.
            this.$refs.filter.clearFilter();

        };

        // Define methods.
        const methods = {
            updateFilter,
            getJobListings,
            generateKey,
            resetFilter
        };

    //

    // WATCH

        // Define watch.
        const watch = {
            'filter.location': function () {
            
                // Update query parameter.
                this.$router.push({
                    query: {
                        location: this.filter.location
                    }
                });

            }
        };

    //

    // EXPORT

        export default {
            name: 'JobSearch',
            components,
            data,
            computed,
            methods,
            watch
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .filter {
            @media screen and (min-width: $small) {
            }
            @media screen and (min-width: $medium) {
            }
            @media screen and (min-width: $large) {
            }
        }

        .content {
            @extend .content-center;
            @media screen and (min-width: $small) {
            }
            @media screen and (min-width: $large) {
            }
        }

        .no-results {
            @extend .standard-container;
            gap: 1rem;
            justify-items: center;
            @media screen and (min-width: $small) {
            }
            @media screen and (min-width: $large) {
            }
        }

        .no-results-title {
            @extend .section-title;
        }

        .icon {
            width: 50%;
            max-width: 10rem;
        }
        
        .message {
            text-align: center;
        }

        .reset {
            padding: 1rem 4rem;
        }

    //

</style>
