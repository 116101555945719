<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <section :class="isPromoted ? 'promoted' : ''">
        <div
            v-if="isPromoted"
            class="promoted-label"
        >
            <Image
                filePath="promoted.svg"
                alternativeText="Promoted Job"
                class="icon green"
            />
        Promoted Job
        </div>
        <header>
            <h2>{{ title }}</h2>
        </header>
        <Detail
            filePath="provider.svg"
            alternativeText="Provider"
            :label="provider"
        />
        <Detail
            filePath="location.svg"
            alternativeText="Location"
            :label="location"
        />
        <Detail
            filePath="route.svg"
            alternativeText="Distance"
            :label="distance"
        />
        <Detail
            filePath="money.svg"
            alternativeText="Pay"
            :label="pay"
        />
        <Detail 
            filePath="clock.svg"
            alternativeText="Hours"
            :label="hours"
        />
        <div v-if="carNeeded"> 
            <Detail
                filePath="car.svg"
                alternativeText="Needs Car"
                label="Car Needed"
            />
        </div>
        <div class="body">
            {{ description }}
        </div>
        <div class="actions">
            <Button
                :action="showApplication"
                title="Apply for Job"
                class="action-button"
            >
                <Image
                    filePath="apply.svg"
                    alternativeText="Search"
                    class="icon"
                />
                Apply for Job
            </Button>
            <Button
                :action="goToJobDetails"
                title="View Job"
                class="action-button contrast"
            >
                <Image
                    filePath="search.svg"
                    alternativeText="Search"
                    class="icon"
                />
                View Job
            </Button>
        </div>
    </section>
    <JobApplication
        :id="id"
        :siteURL="siteURL"
        ref="application"
    />
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Image from '@/components/generic/Image';
        import Button from '@/components/generic/Button';
        import Detail from '@/components/generic/Detail';
        import JobApplication from '@/components/modules/JobApplication';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Image,
            Button,
            Detail,
            JobApplication
        };

    //

    // PROPS

        // Define props.
        const props = {
            id: {
                type: String
            },
            siteURL: {
                type: String
            },
            title: {
                type: String
            },
            provider: {
                type: String
            },
            location: {
                type: String
            },
            distance: {
                type: String
            },
            pay: {
                type: String
            },
            description: {
                type: String
            },
            promoted: {
                type: String
            },
            hours: {
                type: String
            },
            needsCar: {
                type: Number
            }
        };

    //

    // COMPUTED

        // Determine whether job has been promoted.
        const isPromoted = function () {
            return this.promoted === "1" ? true : false;
        };

        // Needs car flag
        const carNeeded = function () {
            return this.needsCar === 1 ? true : false;
        }

        // Define computed.
        const computed = {
            isPromoted,
            carNeeded
        };

    //

    // METHODS

        // Go to 'job-details' page.
        const goToJobDetails = function () {

            // Go to page, pass 'id' query parameter.
            this.$router.push({
                name: 'job-details',
                query: {
                    id: this.id,
                    distance: this.distance
                }
            });

        };

        // Show application.
        const showApplication = function () {

            this.$refs.application.show();

        };

        // Define methods.
        const methods = {
            goToJobDetails,
            showApplication
        };

    //

    // EXPORT

        export default {
            components,
            props,
            computed,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        section {
            @extend .standard-container;
        }

        header {
            display: flex;
        }

        h2 {
            @extend .section-title;
        }

    //

    // CLASSES

        .promoted {
            border: 5px solid $colour-primary-trans;
        }

        .promoted-label {
            background-color: $colour-primary-trans;
            color: $colour-light;
            margin: -1.15rem 0 0;
            padding: .25rem 1rem .5rem .75rem;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            font-weight: 300;
            font-size: .75rem;
            letter-spacing: .05rem;
            display: flex;
            align-items: center;
            width: fit-content;
            gap: .25rem;
            .icon {
                width: 1.15rem
            }
        }

        .body {
            color: $colour-dark;
        }

        .actions {
            display: grid;
            gap: .5rem;
            margin-top: .5rem;
            @media screen and (min-width: $small) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (min-width: $large) {
                display: flex;
                justify-content: flex-end;
            }
        }

        .action-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5rem;
            &.contrast {
                background-color: $colour-contrast;
                &:hover {
                    background-color: $colour-highlight;
                }
            }
            .icon {
                height: 1.5rem;
            }
        }

    //

</style>
