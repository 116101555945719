<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div>
        <ValidationPrompter
            :messages="validationMessages"
        />
        <label>
            <div class="checkbox-label">
                {{ label }}
            </div>
            <input
                ref="input"
                type="checkbox"
                :class="isValid ? '' : 'invalid'"
                :value="inputValue"
                v-model="model"
                @input="update"
            />
        </label>
    </div>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import ValidationPrompter from '@/components/generic/ValidationPrompter';

    //

    // COMPONENTS

        // Define components.
        const components = {
            ValidationPrompter
        };

    //

    // PROPS

        // Define props.
        const props = {
            label: {
                type: String
            },
            validation: {
                type: Array
            },
            modelValue: {},
            inputValue: {
                type: String
            }
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                validationMessages: null
            };
        };

    //

    // COMPUTED

        // Model.
        const model = {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        };

        // Determine whether input value is valid.
        const isValid = function () {
            return this.validationMessages && this.validationMessages.length ? false : true;
        };
 
        // Define computed.
        const computed = {
            model,
            isValid
        };

    //

    // METHODS

        // Validate.
        const validate = function () {

            // If validation functions have been defined.
            if (this.validation) {

                // Define array to store validation messages.
                const messages = [];

                // For each validation function.
                for (const index in this.validation) {

                    // Define alias for function.
                    const validationFunction = this.validation[index];

                    // Invoke validation function, passing input value.
                    const response = validationFunction(this.$refs.input.value);

                    // If string is returned.
                    if (typeof response === 'string') {

                        // Push message to array.
                        messages.push(response);

                    }

                }

                // Update validation messages state.
               this.validationMessages = messages.length ? messages : null;

            }

            // Return validation state.
            return this.isValid;

        };

        // Define methods.
        const methods = {
            validate
        };

    //

    // EXPORT

        export default {
            name: 'Input',
            components,
            props,
            data,
            computed,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        div {
            display: grid;
            gap: 0;
        }

        label {
            @extend .input-label;
            display: flex;
        }

        input {
            padding: 1rem;
            margin-left: auto;
            border: none;
            border-radius: .5rem;
            transform: scale(1.5);
            cursor: pointer;
        }

    //

    // CLASSES

        .multi-checkbox {
            font-weight: 300;
            label {
                color: $colour-dark;
            }
        }

        .dark {
            label {
                color: $colour-dark;
            }
        }

    //

</style>
