<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div class="wrapper">
        <Image
            filePath="sad-cloud.svg"
            class="sad-cloud"
        />
        <div class="shadow" />
    </div>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Image from '@/components/generic/Image';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Image
        };

    //

    // EXPORT

        export default {
            name: 'SadCloud',
            components
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // VARIABLES

        $cloud-width: 15rem;
        $cloud-height: calc(#{$cloud-width} * .635);
        $shadow-width: calc(#{$cloud-width} * .75);
        $shadow-height: calc(#{$cloud-width * .1});
        $cloud-travel-distance: 1rem;
        $cloud-shadow-colour: rgba(0, 0, 0, .1);
        $container-size: calc(#{$cloud-height} + #{$cloud-travel-distance} + #{$shadow-height});

    //

    // CLASSES

        .wrapper {
            height: $container-size;
        }

        .sad-cloud {
            width: $cloud-width;
            height: $cloud-height;
            filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .05));
            animation-name: float;
            -webkit-animation-name: float;
            animation-duration: 5s;
            -webkit-animation-duration: 5s;
            animation-iteration-count: infinite;
            -webkit-animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            -webkit-animation-timing-function: ease-in-out;
        }

        @keyframes float {
            from {
                transform: translate(0, 0);
            }
            50% {
                transform: translate(0, $cloud-travel-distance);
            }
            to{
                transform: translate(0, 0);
            }
        }

        @-webkit-keyframes float {
            0% {
                -webkit-transform: translate(0, 0);
            }
            50% {
                -webkit-transform: translate(0, $cloud-travel-distance);
            }
            100% {
                -webkit-transform: translate(0, 0);
            }
        }

        .shadow {
            width: $shadow-width;
            height: $shadow-height;
            background-color: $cloud-shadow-colour;
            box-shadow: 0 0 2px $cloud-shadow-colour;
            border-radius: 100%;
            transform: rotateX(50deg);
            justify-self: center;
            margin-top: calc(#{$cloud-travel-distance} + 1rem);
            animation-name: shadow;
            -webkit-animation-name: shadow;
            animation-duration: 5s;
            -webkit-animation-duration: 5s;
            animation-iteration-count: infinite;
            -webkit-animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            -webkit-animation-timing-function: ease-in-out;
        }

        @keyframes shadow {
            0% {
                width: calc(#{$shadow-width} * .5);
                height: calc(#{$shadow-height} * .5);
            }
            50% {
                width: $shadow-width;
                height: $shadow-height;
            }
            100% {
                width: calc(#{$shadow-width} * .5);
                height: calc(#{$shadow-height} * .5);
            }
        }

        @-webkit-keyframes shadow {
            0% {
                width: calc(#{$shadow-width} * .5);
                height: calc(#{$shadow-height} * .5);
            }
            50% {
                width: $shadow-width;
                height: $shadow-height;
            }
            100% {
                width: calc(#{$shadow-width} * .5);
                height: calc(#{$shadow-height} * .5);
            }
        }

        .sad-cloud, .shadow {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

    //

</style>
